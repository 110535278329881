import { useContext, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { useNavigate } from "react-router-dom";
import { registerParticipant } from "actions";
// import paymentsAPI from "actions/api/payments";
import { decodeDataFromURL, updateLocalQueryParams } from "utils";
import useRankingButton from "./useRankingButton";
import { SdkContext } from "providers/client-sdk-providers";
import { sendEventsInfoToCortex } from "providers/client-sdk-providers/razer";
import { APP_SLUGS } from "constants/constants";
import analytics from "utils/analytics";
import { showPayment } from "redux/reducers/paymentReducer";
import { isBkash, isDana, isOoredoo } from "utils/applicationSlug";
import useModalHandler from "hooks/useModalHandler";
import { hideProgressShareOverlay } from "redux/reducers/gameplayReducer";
import api from "actions/api";
import { hideLeaderboardPopup } from "redux/reducers/layoutReducer";
import { toggleGameListPopup } from "redux/reducers/modalReducer";

export function generateRedirectURL(tournament: ITournament, queryParams: any) {
  const Wlocation = window.location;
  const baseURL = `${Wlocation.protocol}//${Wlocation.host}/tournament`;

  let params = `tournament=${tournament?.id}&`;
  Object.keys(queryParams).map(key => {
    const value = encodeURIComponent(`${queryParams[key]}`);
    params += `${key}=${value}&`;
    return null;
  });
  return `${baseURL}?${params}`;
}

interface IUsePlayNowButtonConfig {
  handlePayment?: {
    onSuccess?: (showVerifyModal: boolean, response: any) => void;
    onError?: Function;
    onFinally?: Function;
  };
  footer?: {
    onClick: Function;
  };
}

export default function usePlayNowButton(tournament: ITournament, game: IGame, config?: IUsePlayNowButtonConfig) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sdk = useContext(SdkContext);
  const { application, country, user } = useAppSelector((state: any) => state.common);
  const { participants } = useAppSelector((state: any) => state.userTournaments);
  const { openLeaderBoardPopUp } = useRankingButton();
  const [buttonLoading, setButtonLoading] = useState(false);
  const participant = participants?.find((p: IParticipant) => p.tournament === tournament?.id);

  const registered = Boolean(participant);
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;

  const directPaymentEnabled = isBkash || isOoredoo; // NOTE: In future add more checks like isBkash || isOredoo
  const { user_info } = useAppSelector((state: any) => state.subscription);
  const enableSubscriptionPackage = application?.enable_subscription && user_info.length === 0;
  const { openSubscriptionModal } = useModalHandler();

  // Handler of the button
  const onPlayNowButtonClick = (e: any) => {
    e?.preventDefault();
    setButtonLoading(true);

    // Send amplitude event
    analytics.setTournamentData(tournament);
    analytics.clickedPlayNow();

    const isTrialBased = tournament?.trial_packages?.length > 0 && tournament?.entry_fee === 0;
    const isTrialBasedAndPlayable = isTrialBased && tournament?.trial_balance > 0;
    const isRegularFree = !isTrialBased && tournament?.entry_fee === 0 && tournament?.entry_fee_type !== "voucher";

    if (isTrialBased) {
      if (isTrialBasedAndPlayable) {
        dispatch(toggleGameListPopup());
        dispatch(hideLeaderboardPopup());

        dispatch(hideProgressShareOverlay());
        navigate(`/tournaments/${tournament?.id}/play`);
      } else {
        if (directPaymentEnabled) {
          dispatch(showPayment());
        } else {
          openLeaderBoardPopUp(tournament);
        }
      }
      setButtonLoading(false);
    } else if (registered || (isGuestUser && isRegularFree)) {
      // Hide leaderboard pop up
      dispatch(hideLeaderboardPopup());
      dispatch(toggleGameListPopup());
      dispatch(hideProgressShareOverlay());
      if (application?.slug === "razer" && tournament?.entry_fee === 0) {
        sendEventsInfoToCortex(["action_goama_free_play", "action_goama_tournament_play"], tournament, user.username);
      } else if (
        application?.slug === "razer" &&
        tournament?.entry_fee > 0 &&
        tournament?.entry_fee_type === "razer-gold"
      ) {
        sendEventsInfoToCortex(["action_goama_gold_play", "action_goama_tournament_play"], tournament, user.username);
      } else if (
        application?.slug === "razer" &&
        tournament?.entry_fee > 0 &&
        tournament?.entry_fee_type === "razer-silver"
      ) {
        sendEventsInfoToCortex(["action_goama_silver_play", "action_goama_tournament_play"], tournament, user.username);
      }

      if (enableSubscriptionPackage && !isGuestUser) {
        //NOTE - SUBSCRIPTION_PURPOSE for registered users

        if (tournament?.trial_gameplay_counter > tournament?.user_total_gameplay) {
          navigate(`/tournaments/${tournament?.id}/play`);
        } else {
          openSubscriptionModal();
        }
      } else {
        dispatch(hideProgressShareOverlay());
        navigate(`/tournaments/${tournament?.id}/play`);
      }

      setButtonLoading(false);
    } else if (user && isRegularFree) {
      //NOTE - FREE GAME
      if (enableSubscriptionPackage) {
        //NOTE - SUBSCRIPTION_PURPOSE

        if (tournament?.trial_gameplay_counter > tournament?.user_total_gameplay) {
          navigate(`/tournaments/${tournament?.id}/play`);
        } else {
          openSubscriptionModal();
        }
      } else {
        // Handle unregistered users
        dispatch<any>(registerParticipant(tournament?.id, user.id))
          .then(() => {
            dispatch(toggleGameListPopup());
            if (application?.slug === "razer" && tournament?.entry_fee === 0) {
              sendEventsInfoToCortex(
                [
                  "action_goama_free_entered",
                  "action_goama_tournament_entered",
                  "action_goama_free_play",
                  "action_goama_tournament_play",
                ],
                tournament,
                user.username,
              );
            }

            dispatch(hideLeaderboardPopup());

            dispatch(hideProgressShareOverlay());
            navigate(`/tournaments/${tournament?.id}/play`);
          })
          .catch((e: Error) => {
            console.error("Could not create participant.", e);
          })
          .finally(() => setButtonLoading(false));
      }
    } else if (!config?.handlePayment) {
      if (config?.footer?.onClick) {
        setButtonLoading(false);
        config.footer.onClick();
      } else {
        if (directPaymentEnabled) {
          dispatch(showPayment());
        } else {
          openLeaderBoardPopUp(tournament);
        }
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onPaymentConfirmationClick = (e: any) => {
    if (tournament?.entry_fee > 0) {
      const queryParams: any = {
        country,
        callback: true,
        app: application?.slug,
        userid: user?.username.split("__")[0],
        tournament: tournament?.id,
      };
      const { userid } = decodeDataFromURL();
      if (!userid) {
        delete queryParams.userid;
      }
      if (application.is_custom_avatar_enabled && user.extra_data.profile_pic) {
        queryParams.profile_pic = user.extra_data.profile_pic;
      }
      if (application.is_custom_avatar_enabled && user.first_name) {
        queryParams.name = user.first_name;
      }

      setButtonLoading(true);

      // Make call to paymentsAPI
      if (sdk?.prePaymentConfirmationCallback) {
        sdk.prePaymentConfirmationCallback(tournament);
      }

      const axiosOptions = isDana ? { timeout: 20000 } : {};
      return api
        .post(
          `payments/init/${application?.slug}/`,
          {
            amount: tournament?.entry_fee,
            tournament: tournament?.id,
            redirect_link: generateRedirectURL(tournament, queryParams),
            currency: tournament?.entry_fee_currency
              ? tournament?.entry_fee_currency
              : application?.payment_channel?.currency_symbol,
          },
          axiosOptions,
        )
        .then((response: any) => {
          // Set last reference id into localStorage
          // For verifying upon callback redirect
          localStorage?.setItem(`active-ref-${tournament?.id}`, response.data.reference);
          // Set new_userid into query_params if available
          if (response.data?.new_userid) {
            updateLocalQueryParams({ userid: response.data.new_userid });
          }
          return response;
        })
        .then((response: any) => {
          if (config?.handlePayment?.onSuccess) {
            const showVerifyModal = sdk.postPaymentShowVerifyModal === undefined || sdk.postPaymentShowVerifyModal;
            if (application?.slug === APP_SLUGS.BKASH) {
              // config.handlePayment.onSuccess(showVerifyModal, response);
            } else {
              config.handlePayment.onSuccess(showVerifyModal, response);
            }
          }
          return response;
        })
        .then(response => {
          const { data } = response;
          if (sdk?.postPaymentConfirmationCallback) {
            sdk.postPaymentConfirmationCallback(data);
          } else if (window.top && data?.top_redirect) {
            window.top.location.href = data?.checkoutURL || data?.deeplinkURL;
          } else if (data?.deeplinkURL || data?.checkoutURL) {
            window.location.href = data?.deeplinkURL || data?.checkoutURL;
          }

          return data;
        })
        .catch(e => {
          if (config?.handlePayment?.onError) {
            console.error(e);
            config.handlePayment.onError(e);
          }
        })
        .finally(() => {
          setButtonLoading(false);
          config?.handlePayment?.onFinally && config.handlePayment.onFinally();
        });
    }
  };

  return {
    onPlayNowButtonClick,
    onPaymentConfirmationClick,
    registered,
    buttonLoading,
    setButtonLoading,
  };
}
